import React, { useState } from "react";
import { Divider, Input, Select, Space, Button, Tooltip } from "antd";
import { useEffect } from "react";
import "./selected.css"; // 引入样式文件
const { Option } = Select;

const AirportSelectorArrival = ({
  onAirportSelect,
  onAirportChange,
  onAirportSearch,
  allAirports,
  nearbyAirportsAText,
  arrivalDataBack,
  maxCountA,
}) => {
  useEffect(() => {
    setSelectedAirports(arrivalDataBack);
  }, [arrivalDataBack]);
  const [selectedAirports, setSelectedAirports] = useState([]);
  const [inputValue, setInputValue] = useState("");
  let [timer] = useState(null);

  const handleChange = (selectedItems, options) => {
    console.log("Selected:", selectedItems);
    setSelectedAirports(selectedItems);
    onAirportChange(options);
  };

  const handleSelect = (value, option) => {
    console.log(nearbyAirportsAText);
    if (!nearbyAirportsAText) {
      onAirportSelect(option.iataCode, "A");
    }
  };

  const handleSearch = (value) => {
    console.log(value);
    clearTimeout(timer);
    timer = setTimeout(() => {
      setInputValue(value);
      onAirportSearch(value, "A");
    }, 500);
  };

  const filterOption = (input, option) => {
    if (!input) return false; // 如果没有输入，则不显示下拉列表
    const childText = Array.isArray(option.children)
      ? option.children.join(" ")
      : option.children.toString();
    const text = childText.toLowerCase();
    const description = option.description.toLowerCase();
    return (
      text.includes(input.toLowerCase()) ||
      description.includes(input.toLowerCase())
    );
  };

  const renderOptions = () => {
    if (!inputValue) return []; // 如果没有输入值，不渲染任何选项
    return Object.values(allAirports).map((airport) => (
      <Option
        key={airport.id}
        value={airport.city}
        iataCode={airport.iataCode}
        description={`${airport.name} ${airport.cityPinyin} ${airport.iataCode}`}
      >
        {airport.city}
        {airport.iataCode}
        {nearbyAirportsAText
          ? "(" + parseInt(airport.relativeDistance) + "公里)"
          : ""}
      </Option>
    ));
  };

  return (
    <div class="my-select">
      {/* <p>{maxCountA}</p> */}
      <Select
        maxTagCount="responsive"
        mode="multiple"
        maxCount={maxCountA}
        style={{ width: "100%" }}
        size="large"
        placeholder="输入多个机场名、城市名或IATA三字码"
        dropdownRender={(menu) =>
          nearbyAirportsAText ? (
            <>
              <Space style={{ padding: "0 8px 4px" }}>
                <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                  {nearbyAirportsAText?.split("附近机场")[0]}附近机场：
                </span>
              </Space>
              <Divider style={{ margin: "8px 0", marginTop: 0 }} />
              {menu}
            </>
          ) : (
            <>{menu}</>
          )
        }
        value={selectedAirports}
        onChange={handleChange}
        onSelect={handleSelect}
        onSearch={handleSearch}
        optionLabelProp="label"
        filterOption={filterOption}
        showSearch
        notFoundContent={null}
        maxTagPlaceholder={(omittedValues) => (
          <Tooltip title={omittedValues.map(({ label }) => label).join(", ")}>
            <span>+更多</span>
          </Tooltip>
        )}
      >
        {renderOptions()}
      </Select>
    </div>
  );
};

export default AirportSelectorArrival;
