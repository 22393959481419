import React, { useState } from "react";
import { Card, Col, Divider, Row, Spin } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import "./CheapRoute.css"; // 引入样式文件

export function isMobileDevice() {
  return (
    typeof navigator !== undefined &&
    /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  );
}
const CheapRoute = ({ cheapRoute, allAirports, loading }) => {
  const isMobile = isMobileDevice();

  const parseChineseDate = (dateStr) => {
    // console.log(allAirports);
    return (
      parseInt(dateStr.slice(4, 6)) +
      "月" +
      parseInt(dateStr.slice(6, 8)) +
      "日"
    );
  };
  console.log("便宜线路", cheapRoute);
  const gridStyle = {
    width: "25%",
    textAlign: "center",
  };
  const renderPc = () => {
    return loading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          height: "100%",
          marginTop: 100,
        }}
      >
        <Spin size="large" />
      </div>
    ) : (
      <Card title="特价线路">
        {cheapRoute.map((item, index) => (
          <Card.Grid key={index} style={gridStyle}>
            <div>
              <div>出发日期：{parseChineseDate(item.date)}</div>
              <div>
                <span style={{ fontSize: "16px" }}>
                  {allAirports[item.departure].city}
                </span>
                &nbsp;&nbsp;
                <ArrowRightOutlined
                  style={{ color: "green", fontSize: "15px" }}
                />
                &nbsp;&nbsp;
                <span style={{ fontSize: "16px" }}>
                  {allAirports[item.arrival].city}
                </span>
              </div>
              <div>
                价格：
                <span
                  style={{
                    color: "red",
                    fontSize: "16px",
                    fontWeight: "Bold",
                  }}
                >
                  ￥{item.price}
                </span>
              </div>
              <a href={item.listMobileLink}>携程预订</a>
            </div>
          </Card.Grid>
        ))}
      </Card>
    );
  };
  const renderMobile = () => {
    return loading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          height: "100%",
          marginTop: 100,
        }}
      >
        <Spin size="large" />
      </div>
    ) : (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          borderTop: "1px solid #eee",
          paddingTop: 16,
        }}
      >
        <div style={{ backgroundColor: "#46bfb3", color: "white", padding: 8 }}>
          特价路线 👇
        </div>
        {cheapRoute.map((item, index) => (
          <div
            key={index}
            style={{
              border: "1px solid #eee",
              borderRadius: "8px",
              padding: 12,
            }}
          >
            <div>出发日期：{parseChineseDate(item.date)}</div>
            <div>
              <span style={{ fontSize: "16px" }}>
                {allAirports[item.departure].city}
              </span>
              <ArrowRightOutlined
                style={{ color: "green", fontSize: "15px" }}
              />
              <span style={{ fontSize: "16px" }}>
                {allAirports[item.arrival].city}
              </span>
            </div>
            <div>
              价格：
              <span
                style={{
                  color: "red",
                  fontSize: "16px",
                  fontWeight: "Bold",
                }}
              >
                ￥{item.price}
              </span>
            </div>
            <a href={item.listMobileLink}>携程预订</a>
          </div>
        ))}
      </div>
    );
  };
  return (
    <div class="cheap-route" style={{ minHeight: isMobile ? 100 : 200 }}>
      {!isMobile ? renderPc() : renderMobile()}
    </div>
  );
};
export default CheapRoute;
