import React from "react";
import { Button, Table, Tag } from "antd";
import { SpaceContext } from "antd/es/space";

const SearchResult = ({ searchResult, allAirports, searchLoading }) => {
  const parseChineseDate = (dateStr) => {
    // console.log(allAirports);
    return (
      parseInt(dateStr.slice(4, 6)) +
      "月" +
      parseInt(dateStr.slice(6, 8)) +
      "日"
    );
  };
  const convertDateToTimestamp = (dateString) => {
    let timestamp = Date.parse(
      dateString.substring(0, 4) +
        "-" +
        dateString.substring(4, 6) +
        "-" +
        dateString.substring(6, 8)
    );
    return timestamp;
  };

  const dict = {
    0: "日",
    1: "一",
    2: "二",
    3: "三",
    4: "四",
    5: "五",
    6: "六",
  };

  const columns = [
    {
      title: "出发日期",
      dataIndex: "date",
      key: "date",
      defaultSortOrder: "",
      sorter: (a, b) => b.date - a.date,
      render: (text, record) => {
        // console.log(record.departure, record.arrival);
        // console.log("KEYS", allAirports);
        // console.log("KEYS", allAirports[record.departure]);
        return (
          <>
            <span>{parseChineseDate(record.date)}</span>
            &nbsp;&nbsp;
            <Tag
              color={
                new Date(convertDateToTimestamp(record.date)).getDay() === 0 ||
                new Date(convertDateToTimestamp(record.date)).getDay() === 6
                  ? "red"
                  : "green"
              }
            >
              星期{dict[new Date(convertDateToTimestamp(record.date)).getDay()]}
            </Tag>
          </>
          //   parseChineseDate(record.date) +
          //   ' 星期' +
          //   dict[new Date(convertDateToTimestamp(record.date)).getDay()]
        );
      },
    },
    {
      title: "出发",
      dataIndex: "departure",
      key: "departure",
      render: (text, record) => {
        console.log(allAirports, record);
        return allAirports[record.departure].city;
      },
    },
    {
      title: "目的",
      dataIndex: "arrival",
      key: "arrival",
      render: (text, record) => {
        return allAirports[record.arrival].city;
      },
    },
    {
      title: "价格",
      dataIndex: "price",
      key: "price",
      defaultSortOrder: "",
      sorter: (a, b) => b.price - a.price,
      render: (text, record) => {
        return record.price + "元";
      },
    },
    {
      title: "操作",
      key: "action",
      render: (text, record) => (
        <Button type="primary" href={record.listMobileLink} target="_blank">
          携程预订
        </Button>
      ),
    },
  ];

  console.log(searchResult, "===");

  return (
    <Table
      defaultFilteredValue
      loading={searchLoading}
      dataSource={searchResult}
      columns={columns}
      rowKey={(record) => record.departure + record.date + record.arrival}
      pagination={{
        defaultPageSize: 10,
        pageSizeOptions: [10, 15, 20],
        showQuickJumper: true,
        showSizeChanger: true,
        locale: {
          items_per_page: "条/页",
          jump_to: "跳至",
          page: "页",
        },
      }}
      locale={{ emptyText: "暂无数据" }}
    />
  );
};

export default SearchResult;
