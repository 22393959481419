import React from "react";
import { DatePicker, ConfigProvider, Divider } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn"; // 加载中文语言包
import locale from "antd/locale/zh_CN";
import "./selected.css"; // 引入样式文件
dayjs.locale("zh-cn"); // 使用中文语言

export function isMobileDevice() {
  return (
    typeof navigator !== undefined &&
    /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  );
}

const { RangePicker } = DatePicker;
const rangePresets = [
  {
    label: "最近3天",
    value: [dayjs().add(2, "d"), dayjs()],
  },
  {
    label: "最近7天",
    value: [dayjs().add(6, "d"), dayjs()],
  },
  {
    label: "最近15天",
    value: [dayjs().add(14, "d"), dayjs()],
  },
  {
    label: "最近30天",
    value: [dayjs().add(29, "d"), dayjs()],
  },
];
const isMobile = isMobileDevice();
const convertDateToTimestamp = (dateString) => {
  let timestamp =
    dateString.substring(0, 4) +
    "-" +
    dateString.substring(4, 6) +
    "-" +
    dateString.substring(6, 8);

  console.log(timestamp, 122222);
  return timestamp;
};
const DateRange = ({ onDateChange }) => {
  const dateFormat = "YYYY-MM-DD";
  const disabledDate = (current) => {
    // Can not select days before today and more than 188 days ahead
    return (
      current &&
      (current < dayjs().startOf("day") || current > dayjs().add(188, "days"))
    );
  };
  let obj = JSON.parse(localStorage.getItem("history"));
  let defaultRange = [];
  if (obj) {
    defaultRange = [
      dayjs(convertDateToTimestamp(obj.departureStartDate), dateFormat),
      dayjs(convertDateToTimestamp(obj.departureEndDate), dateFormat),
    ];
  } else {
    defaultRange = [dayjs().add(3, "days"), dayjs().add(10, "days")];
  }
  console.log(defaultRange, "时间啊啊");
  const handleDateChange = (dates) => {
    console.log("Selected Dates:", dates);
    if (onDateChange) {
      onDateChange(dates);
    }
  };

  return (
    <div class="my-picker" style={{ width: "100%" }}>
      <ConfigProvider locale={locale}>
        <RangePicker
          size="large"
          presets={!isMobile && rangePresets}
          defaultValue={defaultRange}
          disabledDate={disabledDate}
          onChange={handleDateChange}
          format="YYYY-MM-DD"
          placeholder={["最早出发日期", "最晚出发日期"]}
          style={{ width: "100%" }}
        />
      </ConfigProvider>
    </div>
  );
};

export default DateRange;
