import React from "react";
import "./Footer.css"; // 引入样式文件
import { Alert } from "antd";

const Footer = () => {
  return (
    <div className="footer">
      <Alert
        message="本页面允许您在无需登录的情况下查询与携程平台相同的最低机票价格，并确保不会收集任何个人信息。完成机票查询后，点击“携程预订”即可直接跳转至携程移动版网页以相同的价格进行预订。"
        description="如有任何疑问，请通过小红书联系我，ID: 3882144370。"
        type="info"
        showIcon
      />
      <p>
        <br />
          <a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">
              沪ICP备2024065095号-2
      </a>
    </p>
</div>
)
    ;
};

export default Footer;
